import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class DetailsService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "details";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }


  public getLocationVariations(start_date: Date, end_date: Date) {
    return this.getData("getLocationVariations", { start_date: start_date, end_date: end_date }, (response) => { return response.data; });
  }

  public getLastEvents(item_id: string, item_kind: string, is_admin: boolean) {
    return this.getData("getLastEvents", { item_id: item_id, item_kind: item_kind, is_admin: is_admin }, (response) => { return response.data; });
  }

  public getLastEventsForFloors(building_id: string, floor_id: string, is_admin: boolean) {
    return this.getData("getLastEventsForFloors", { building_id: building_id, floor_id: floor_id, is_admin: is_admin }, (response) => { return response.data; });
  }

  public getEventHistory(item_id: string, item_kind: string, is_admin: boolean, start_date: Date, end_date: Date) {
    return this.getData("getEventHistory", { item_id: item_id, item_kind: item_kind, is_admin: is_admin, start_date: start_date, end_date: end_date }, (response) => { return response.data; });
  }

  public getEventGroupedHistory(item_id: string, item_kind: string, start_date: Date, end_date: Date) {
    return this.getData("getEventGroupedHistory", { item_id: item_id, item_kind: item_kind, start_date: start_date, end_date: end_date }, (response) => { return response.data; });
  }

  public getEquipmentHistory(equipment_id: string, start_date: Date, end_date: Date) {
    return this.getData("getEquipmentHistory", { equipment_id: equipment_id, start_date: start_date, end_date: end_date }, (response) => { return response.data; });
  }
}
